import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocalizationService } from "../../services/localization";
import { Router, Resolve, ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd } from "@angular/router";
import { CashbackService } from "../../services/cashback";
import { Group } from "../../models/group.model";

import { CASHBACK_PARTNER_TYPE } from "../../services/cashback";

enum BONUS_LEFT_MENU_ITEM {
    BONUS_LEFT_MENU_ITEM_OVERVIEW = 0,
    BONUS_LEFT_MENU_ITEM_YOUR_CARDS = 1,
    BONUS_LEFT_MENU_ITEM_RECEIPTS = 2,
    BONUS_LEFT_MENU_ITEM_HOW_IT_WORKS = 3,
    BONUS_LEFT_MENU_ITEM_DISTRIBUTE = 4,
    BONUS_LEFT_MENU_ITEM_PARTNER_DETAILS = 5,
    BONUS_LEFT_MENU_ITEM_GROUP_INFO = 6,
    BONUS_LEFT_MENU_ITEM_ONLINE_CASHBACK = 7,
    BONUS_LEFT_MENU_ITEM_ONLINE_PARTNER_DETAILS = 8,
}

@Component({
    selector: "cashback-left-menu",
    template: require("./cashbackLeftMenu.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./cashbackLeftMenu.component.scss").default],
})
export class CashbackLeftMenuComponent implements OnInit {
    @Input()
    partner: any;

    group: Group;
    authCode: String;
    profileId: String;
    objectId: String;
    activeItem: BONUS_LEFT_MENU_ITEM;
    stateName: String;

    public BONUS_LEFT_MENU_ITEM = BONUS_LEFT_MENU_ITEM;

    constructor(
        public i18n: LocalizationService,
        private cashbackService: CashbackService,
        private router: Router,
        private activeRouter: ActivatedRoute,
    ) {
        activeRouter.queryParams.subscribe((params) => {
            this.authCode = params["authCode"];
            this.profileId = params["profileId"];
            this.refreshActiveItem();
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.objectId = window.location.pathname.split("/").pop();
                this.refreshActiveItem();
                window.scrollTo(0, 0);
            }
        });
    }

    refreshActiveItem() {
        var url = window.location.pathname;
        if (url.includes("receipts")) {
            this.activeItem = BONUS_LEFT_MENU_ITEM.BONUS_LEFT_MENU_ITEM_RECEIPTS;
        } else if (url.includes("distribute")) {
            this.activeItem = BONUS_LEFT_MENU_ITEM.BONUS_LEFT_MENU_ITEM_DISTRIBUTE;
        } else if (url.includes("online")) {
            this.activeItem = BONUS_LEFT_MENU_ITEM.BONUS_LEFT_MENU_ITEM_ONLINE_CASHBACK;
        } else if (url.includes("cards")) {
            this.activeItem = BONUS_LEFT_MENU_ITEM.BONUS_LEFT_MENU_ITEM_YOUR_CARDS;
        } else if (url.includes("group")) {
            this.activeItem = BONUS_LEFT_MENU_ITEM.BONUS_LEFT_MENU_ITEM_GROUP_INFO;
            if (this.objectId && this.authCode) {
                this.cashbackService.getGroupBalance(this.objectId, this.authCode).subscribe((group) => {
                    this.group = group;
                });
            }
        } else if (url.includes("partner")) {
            if (this.objectId && this.authCode) {
                this.cashbackService.retrievePartnerById(this.objectId).subscribe((partner) => {
                    this.partner = partner;
                    if (this.partner.type == CASHBACK_PARTNER_TYPE.ONLINE) {
                        this.activeItem = BONUS_LEFT_MENU_ITEM.BONUS_LEFT_MENU_ITEM_ONLINE_PARTNER_DETAILS;
                    } else {
                        this.activeItem = BONUS_LEFT_MENU_ITEM.BONUS_LEFT_MENU_ITEM_PARTNER_DETAILS;
                    }
                });
            }
        } else {
            this.activeItem = BONUS_LEFT_MENU_ITEM.BONUS_LEFT_MENU_ITEM_OVERVIEW;
            this.group = null;
            this.partner = null;
        }
    }

    onOverviewClicked() {
        this.router.navigate(["login"], { queryParams: { authCode: this.authCode, profileId: this.profileId } });
        this.activeItem = BONUS_LEFT_MENU_ITEM.BONUS_LEFT_MENU_ITEM_OVERVIEW;
    }

    onOnlineClicked() {
        this.router.navigate(["online"], { queryParams: { authCode: this.authCode, profileId: this.profileId } });
        this.activeItem = BONUS_LEFT_MENU_ITEM.BONUS_LEFT_MENU_ITEM_ONLINE_CASHBACK;
    }

    onBankCardsClicked() {
        this.router.navigate(["cards"], { queryParams: { authCode: this.authCode, profileId: this.profileId } });
        this.refreshActiveItem();
    }

    onReceiptsClicked() {
        this.router.navigate(["receipts"], { queryParams: { authCode: this.authCode, profileId: this.profileId } });
        this.activeItem = BONUS_LEFT_MENU_ITEM.BONUS_LEFT_MENU_ITEM_RECEIPTS;
    }

    ngOnInit() {
        this.refreshActiveItem();
    }
}
