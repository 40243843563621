import { CurrencyService } from "../services/currency";

export class Transaction {
    id: string;
    amount: number;
    total: number;
    unconfirmedAmount: number;
    undistributedAmount: number;
    accountNo: string;
    accountOwner: string;
    currency: string;
    name: string;
    timestamp: string;
    isStartupBonus: boolean;
    isPending: boolean;
    storeName: string;
    percentage: number;
    simpleId: string;
    yearAndMonth: string;
    payoutPeriod: string;
    paidOutTimestamp: string;
    vm: any = {};
    partnerLogo: string;
    partnerName: string;
    groupName: string;
    color: string;

    constructor(
        obj: any,
        private currencyService: CurrencyService = null,
    ) {
        this.amount = obj.amount;
        this.undistributedAmount = obj.undistributedAmount;
        this.unconfirmedAmount = obj.unconfirmedAmount;
        this.currency = obj.currency;
        this.timestamp = obj.timestamp;
        this.storeName = obj.storeName;
        this.name = obj.name;
        this.id = obj.id;
        this.percentage = obj.percentage;
        this.simpleId = obj.simpleId;
        this.total = obj.total;
        this.payoutPeriod = obj.payoutPeriod;
        this.partnerName = obj.partnerName;
        this.partnerLogo = obj.partnerLogo;
        this.groupName = obj.groupName;
        this.color = obj.color;

        this.accountOwner = obj.accountOwner;
        this.accountNo = obj.accountNo;

        if (obj.groups) {
            this.vm.groupsString = obj.groups.join(", ");
        }

        // if (obj.groupId) {
        //     groupService.getGroup(obj.groupId).subscribe(group=>{
        //       this.vm.group = group;
        //     });
        // }

        this.vm.isUnconfirmed = obj.status === "pending_confirmation";
        this.vm.isPendingDistribution = obj.status === "pending_distribution";

        this.buildAmounts(obj);
    }

    buildAmounts(obj) {
        var options: any;
        if (this.timestamp) {
            options = { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" };
            this.vm.dateTimeString = new Date(this.timestamp).toLocaleDateString("no-no", options);
            options = { month: "long" };
            this.vm.month = new Date(this.timestamp).toLocaleDateString("no-no", options);
        } else {
            var payoutDate = new Date(this.payoutPeriod);
            if (payoutDate.getFullYear() == new Date().getFullYear()) {
                options = { month: "long" };
            } else {
                options = { month: "long", year: "numeric", day: "numeric" };
            }

            this.vm.month = payoutDate.toLocaleDateString("no-no", options);
        }

        this.currencyService.formatAmountExact(obj.amount, obj.currency).subscribe((formatted) => {
            this.vm.formattedAmount = formatted;
        });

        if (obj.total) {
            this.currencyService.formatAmountExact(obj.total, obj.currency).subscribe((formatted) => {
                this.vm.formattedTotal = formatted;
            });
        }

        if (obj.undistributedAmount !== undefined) {
            this.currencyService.formatAmountExact(obj.undistributedAmount, obj.currency).subscribe((formatted) => {
                this.vm.formattedUndistributedAmount = formatted;
            });
        }

        if (obj.unconfirmedAmount !== undefined) {
            this.currencyService.formatAmountExact(obj.unconfirmedAmount, obj.currency).subscribe((formatted) => {
                this.vm.formattedUnconfirmedAmount = formatted;
            });
        }
    }
}
