const values = new Map<string, any>();
const win = window as any;

let storage = {
    setItem(key: string, value: any) {
        values.set(key, value);
    },
    getItem(key: string) {
        return values.get(key);
    },
    removeItem(key: string) {
        values.delete(key);
    },
    clear() {
        values.clear();
    },
};

try {
    win.localStorage.setItem("__test__", "__test__");
    win.localStorage.getItem("__test__");
    storage = win.localStorage;
} catch (e) {
    try {
        win.sessionStorage.setItem("__test__", "__test__");
        win.sessionStorage.getItem("__test__");
        storage = win.sessionStorage;
    } catch (e) {}
}

export default storage as {
    getItem(key: string): any;
    setItem(key: string, value: any): void;
    removeItem(key: string): void;
    clear(): void;
};
