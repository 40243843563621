import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

import { Group } from "../../models/group.model";

@Component({
    selector: "group-cover-image",
    template: require("./groupCoverImage.component.html").default,
    //styles: [require('../../styles/index.scss').default, require('./groupCoverImage.component.scss').default],
})
export class GroupCoverImageComponent {
    @Input()
    group: Group;
    @Input()
    width: number;
    @Input()
    height: number;
    @Input()
    sourceWidth: number;
    @Input()
    sourceHeight: number;
    @Input()
    borderRadius: number;

    adjustedBorderRadius: String;
    style: any;
    transformStyle: any;
    innerStyle: any;
    outerStyle: any;
    marginTopStyle: any;

    cropWidth: number;
    cropHeight: number;

    constructor() {}

    render() {
        var groupId: string = this.group.id ? this.group.id : "abc";
        var hex = groupId.substring(groupId.length - 2);

        var idx = Math.floor(parseInt("0x" + hex) / 2);

        var x = Math.floor((idx / 8) % 4) * -(this.width / 2 / 3);
        var y = Math.floor((idx / 32) % 4) * -(this.height / 2 / 3);
        var s = Math.floor((idx / 4) % 2);
        var r = Math.floor(idx % 4) * 90;

        var imageUrl;
        var backgroundColor = this.group.vm.isSubGroup ? this.group.color : "#0080A3";
        if (this.group.vm.isSubGroup) {
            imageUrl = "url('../../../assets/group_cover_pattern.jpg')";
        } else {
            imageUrl = "url('../../../assets/subgroup_cover_pattern.jpg')";
        }

        this.outerStyle = {
            width: this.width + "px",
            height: this.height + "px",
            "border-radius": this.adjustedBorderRadius,
        };

        this.style = {
            transform: "rotate(" + r + "deg) scale(" + 1 + ", " + (s ? -1 : 1) + ")",
            backgroundPosition: x + "px " + y + "px",
            backgroundColor: backgroundColor,
            backgroundImage: imageUrl,
        };

        this.transformStyle = { transform: this.style.transform };
        this.innerStyle = {
            width: this.cropWidth / 2 + "px",
            height: this.cropHeight / 2 + "px",
            "background-size": this.cropWidth + "px " + this.cropHeight + "px",
            "background-image": this.style.backgroundImage,
            "background-position": this.style.backgroundPosition,
            "background-color": this.style.backgroundColor,
        };
        this.marginTopStyle = { "margin-top": -this.cropHeight / 6 + "px" };
    }

    ngOnInit() {
        var width = 1580;
        var height = 1580;

        if (this.sourceWidth) {
            width = this.sourceWidth;
        }

        if (this.sourceHeight) {
            height = this.sourceHeight;
        }

        if (this.borderRadius) {
            this.adjustedBorderRadius = this.borderRadius + "px";
        } else {
            this.adjustedBorderRadius = "0px";
        }

        this.cropWidth = width;
        this.cropHeight = height;

        this.render();
    }
}
