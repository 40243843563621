import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "replaceHighlight" })
export class ReplaceHighlightPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(input: String, url: String, url1: String, keepLinkStyle: boolean) {
        if (typeof input !== "string") {
            return undefined;
        }
        let result = null;
        const urlForwardPattern = /<highlight>/;
        const urlAfterwardsPattern = /<\/highlight>/g;
        if (url) {
            input = input.replace(
                urlForwardPattern,
                '<a style="color:#0080A3;cursor:pointer;" target="_blank" href="' + url + '">',
            );
            if (url1) {
                input = input.replace(
                    urlForwardPattern,
                    '<a style="color:#0080A3;cursor:pointer;" target="_blank" href="' + url1 + '">',
                );
            }

            result = input.replace(urlAfterwardsPattern, "</a>");
        } else if (keepLinkStyle) {
            input = input.replace(urlForwardPattern, '<a style="color:#0080A3;cursor:pointer;text-weight:500;">');
            result = input.replace(urlAfterwardsPattern, "</a>");
        } else {
            input = input.replace(urlForwardPattern, '<span style="color:black; text-weight:500;">');
            result = input.replace(urlAfterwardsPattern, "</span>");
        }
        return this.sanitizer.bypassSecurityTrustHtml(result);
    }
}
