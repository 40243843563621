import { CurrencyService } from "../services/currency";

export class Group {
    id: string;
    name: string;
    imageUrl: string;
    vm: any = {};
    color: any;
    type: any;
    currency: string;
    startupBonus: number;
    paidOut: number;
    totalCollected: number;
    userCollected: number;
    primaryContact: string;
    supportCode: string;
    isPendingAdmin: boolean;
    members: any[];
    memberMaySpond: boolean;
    guardianMaySpond: boolean;
    memberMayPost: boolean;
    guardianMayPost: boolean;
    bonusEnabled: boolean;
    supportUrl: string;
    fjordkraftSupportUrl: string;

    constructor(
        obj: any,
        private currencyService: CurrencyService = null,
    ) {
        this.id = obj.id;
        this.name = obj.name;
        this.imageUrl = obj.imageUrl;
        this.color = obj.color;
        this.type = obj.type;
        this.primaryContact = obj.primaryContact;
        this.isPendingAdmin = obj.isPendingAdmin;
        this.members = obj.members;
        this.bonusEnabled = obj.bonusEnabled;
        this.supportCode = obj.supportCode;
        this.currency = obj.currency;

        this.startupBonus = obj.startupBonus;
        this.paidOut = obj.paidOut;
        this.totalCollected = obj.totalCollected;
        this.userCollected = obj.userCollected;
        this.supportUrl = obj.supportUrl;
        this.fjordkraftSupportUrl = obj.fjordkraftSupportUrl;

        this.vm.isSubGroup = false;

        this.buildGroupSupportInfo(obj);
    }

    buildGroupSupportInfo(groupInfo) {
        if (groupInfo.totalCollected !== undefined) {
            this.currencyService
                .formatAmountExact(groupInfo.totalCollected, groupInfo.currency)
                .subscribe((formatted) => {
                    this.vm.formattedTotalCollected = formatted;
                });
        }

        if (groupInfo.userCollected !== undefined) {
            this.currencyService
                .formatAmountExact(groupInfo.userCollected, groupInfo.currency)
                .subscribe((formatted) => {
                    this.vm.formattedUserCollected = formatted;
                });
        }

        if (groupInfo.paidOut !== undefined) {
            this.currencyService.formatAmountExact(groupInfo.paidOut, groupInfo.currency).subscribe((formatted) => {
                this.vm.formattedPaidOut = formatted;
            });
        }

        if (groupInfo.startupBonus !== undefined) {
            this.currencyService
                .formatAmountExact(groupInfo.startupBonus, groupInfo.currency)
                .subscribe((formatted) => {
                    this.vm.formattedStartupBonus = formatted;
                });
        }

        if (groupInfo.paidOut !== undefined) {
            this.currencyService.formatAmountExact(groupInfo.paidOut, groupInfo.currency).subscribe((formatted) => {
                this.vm.formattedPaidOut = formatted;
            });

            this.currencyService
                .formatAmountExact(groupInfo.totalCollected - groupInfo.paidOut, groupInfo.currency)
                .subscribe((formatted) => {
                    this.vm.formattedPendingFunds = formatted;
                });
        }
    }
}
