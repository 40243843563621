import getSimulatedHostnameAndRenderBadge from "./getSimulatedHostnameAndRenderBadge";

const API_NOT_CONFIGURED_WARNING = "api-not-configured-warning";
export const NAME_FORBIDDEN_CHARS = ["\\", "/", ".", ":", "+", "&"];
export const MAX_PROFILE_NAME_LENGTH = 50;
export const MAX_GROUP_NAME_LENGTH = 60;

let API_URL = `${document.location.origin}/core/v1`;
let BASE_API_URL = `${document.location.origin}`;
let COMET_API_URL = `${document.location.origin}/comet/v1`;
let FR_API_URL = `${document.location.origin}/fr-api`;
let ECB_API_URL = `${document.location.origin}/ecb`;

const simulatedHostname = getSimulatedHostnameAndRenderBadge();

switch (simulatedHostname || window.location.hostname) {
    case "dev1.spond.dev": {
        API_URL = "https://api-dev1.spond.dev/core/v1";
        BASE_API_URL = "https://api-dev1.spond.dev";
        COMET_API_URL = "https://api-dev1.spond.dev/comet/v1";
        FR_API_URL = "https://api-dev1.spond.dev/frc/v1";
        ECB_API_URL = "https://api-dev1.spond.dev/ecb/v1";
        break;
    }
    case "spond.com": {
        API_URL = "https://api.spond.com/core/v1";
        BASE_API_URL = "https://api.spond.com";
        COMET_API_URL = "https://api.spond.com/comet/v1";
        FR_API_URL = "https://api.spond.com/frc/v1";
        ECB_API_URL = "https://api.spond.com/ecb/v1";
        break;
    }
    case "staging1.spond.dev": {
        API_URL = "https://api-staging1.spond.dev/core/v1";
        BASE_API_URL = "https://api-staging1.spond.dev";
        COMET_API_URL = "https://api-staging1.spond.dev/comet/v1";
        FR_API_URL = "https://api-staging1.spond.dev/frc/v1";
        ECB_API_URL = "https://api-staging1.spond.dev/ecb/v1";
        break;
    }
    case "cwdev1.spond.dev": {
        API_URL = "https://api-cwdev1.spond.dev/core/v1";
        BASE_API_URL = "https://api-cwdev1.spond.dev";
        COMET_API_URL = "https://api-cwdev1.spond.dev/comet/v1";
        FR_API_URL = "https://api-cwdev1.spond.dev/frc/v1";
        ECB_API_URL = "https://api-cwdev1.spond.dev/ecb/v1";
        break;
    }
    default: {
        if (window.location.hostname === "localhost") {
            API_URL = "http://localhost:18080/core/v1";
            BASE_API_URL = "http://localhost:18080";
            COMET_API_URL = "http://localhost:18080/comet/v1";
            FR_API_URL = "http://localhost:18080/frc/v1";
            ECB_API_URL = "http://localhost:18081/ecb/v1";
            break;
        }
        sendMissingConfigurationWarningToSentry();
        break;
    }
}

function sendMissingConfigurationWarningToSentry() {
    setTimeout(() => {
        try {
            const hasWarned = sessionStorage.getItem(API_NOT_CONFIGURED_WARNING) === "true";

            // To prevent spamming Sentry, we only send this warning
            // once for each browser session.
            if (hasWarned) {
                return;
            }

            sessionStorage.setItem(API_NOT_CONFIGURED_WARNING, "true");
        } catch (e) {
            // Not in an environment where sessionStorage is allowed.
            // We do not need to send a Sentry alert, as it will be
            // covered by other devices.
            return;
        }
        throw Error(`API usage not updated for ${window.location.origin}. Defaulting to ${window.location.origin}.`);
    });
}

export { API_URL, BASE_API_URL, COMET_API_URL, FR_API_URL, ECB_API_URL };
