import { NgModule } from "@angular/core";
import { ModalModule } from "ng2-bootstrap/modal";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { SpinnerButtonModule } from "../../../common/components/spinnerButton";
import { GroupLinkComponent } from "./groupLink.component";
import { GroupLinkService } from "./shared/groupLink.service";
import { SpondCommonModule } from "../../../common/common.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfirmModalModule } from "../../../common/components/confirmModal";

@NgModule({
    declarations: [GroupLinkComponent],
    imports: [
        ModalModule,
        CommonModule,
        SpondCommonModule,
        BrowserAnimationsModule,
        SpinnerButtonModule,
        FormsModule,
        ConfirmModalModule,
    ],
    providers: [GroupLinkService],
    exports: [GroupLinkComponent],
})
export class GroupLinkModule {}
