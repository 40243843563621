import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocalizationService } from "../../services/localization";
import { Router, Resolve, ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";

@Component({
    selector: "cashback-get-started",
    template: require("./cashbackGetStarted.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./cashbackGetStarted.component.scss").default],
})
export class CashbackGetStartedComponent {
    @Input()
    cards: any;
    @Input()
    bonusGroups: any;
    @Input()
    groups: any;
    @Input()
    trigger: any;

    @Output() onGetStarted: EventEmitter<any> = new EventEmitter();

    cashbackEnabledGroups: any[];
    authCode: String;
    profileId: String;

    constructor(
        public i18n: LocalizationService,
        private router: Router,
        private activeRouter: ActivatedRoute,
    ) {
        activeRouter.queryParams.subscribe((params) => {
            this.authCode = params["authCode"];
        });
    }

    onGetStartedClicked() {
        this.onGetStarted.emit();
    }

    onCreateGroup() {}

    onDistribute() {
        this.router.navigate(["distribute"], { queryParams: { authCode: this.authCode, profileId: this.profileId } });
    }

    ngOnInit() {
        if (this.trigger) {
            this.onGetStarted.emit();
        }

        this.cashbackEnabledGroups = this.groups.filter((group) => group.bonusEnabled);
    }
}
