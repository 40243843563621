import { Injectable } from "@angular/core";
import { Http, Response, URLSearchParams } from "@angular/http";
import { Observable } from "rxjs/Observable";

const SUPPORTED_LOCALES: Array<string> = ["en", "en-US", "no", "de", "fr", "es", "it", "nl", "sv", "da"];

const LOCALE_ALIASES: Map<string, any> = new Map([
    ["nb", "no"],
    ["nn", "no"],
    ["no", "no"],
    ["nb-no", "no"],
    ["nn-no", "no"],
    ["de", "de"],
    ["de-de", "de"],
    ["de-at", "de"],
    ["da", "da"],
    ["da-dk", "da"],
    ["en-us", "en"],
    ["en-gb", "en"],
    ["en-ie", "en"],
]);

const DEFAULT_LOCALE = "en";

@Injectable()
export class LocalizationService {
    locale: string;
    locales: Map<string, any> = new Map();

    constructor() {
        SUPPORTED_LOCALES.forEach((locale) => {
            let combinedJson = require(`./locales/${locale}.json`);

            if (locale == "no") {
                let fjordkraftJson = require(`./locales/fjordkraft_${locale}.json`);
                combinedJson = Object.assign({}, combinedJson, fjordkraftJson);
            }

            this.locales.set(locale, combinedJson);
        });

        let nav = navigator as any;
        let browserLocale = nav.languages ? nav.languages[0] : nav.language || nav.userLanguage;
        this.setLocale(browserLocale);
    }

    setLocale(locale: string) {
        if (locale) {
            locale = locale.toLowerCase();
        }

        if (LOCALE_ALIASES.has(locale)) {
            this.locale = LOCALE_ALIASES.get(locale);
        } else if (locale in SUPPORTED_LOCALES) {
            this.locale = locale;
        } else {
            this.locale = DEFAULT_LOCALE;
        }
    }

    getLocale() {
        return this.locale;
    }

    dict(): any {
        return this.locales.get(this.locale);
    }

    fomalizePlaceHolders(text: string): string {
        let maxParamsCount = 5;
        let result = text;

        let replaceTokens = ["%@", "%s", "%d"];
        for (let i = 1; i < maxParamsCount; i++) {
            replaceTokens.push("%" + i + "$@");
        }
        for (let i = 1; i < maxParamsCount; i++) {
            replaceTokens.push("%" + i + "$s");
        }
        for (let i = 1; i < maxParamsCount; i++) {
            replaceTokens.push("%" + i + "$d");
        }

        for (let j = 0; j < replaceTokens.length; j++) {
            let currentToken = replaceTokens[j];
            result = result.replace(currentToken, "%@");
        }

        return result;
    }

    get(key: string, ...args: string[]): string {
        let strings = this.locales.get(this.locale);
        if (!strings || !strings[key]) {
            return `** Missing i18n key ${key} **`;
        }

        let str = strings[key] as string;

        str = this.fomalizePlaceHolders(str);

        args.forEach((arg) => {
            str = str.replace("%@", arg);
        });

        return str;
    }

    pluralize(key: string, count: number, ...args: string[]): string {
        let strings = this.locales.get(this.locale);
        if (!strings || !strings[key]) {
            return `** Missing i18n key ${key} **`;
        }

        let obj = strings[key] as any;
        var str = "";
        if (count == 0 && !!obj["zero"]) {
            str = obj["zero"] as string;
        } else if (count == 1) {
            str = obj["one"] as string;
        } else {
            str = obj["other"] as string;
        }

        str = this.fomalizePlaceHolders(str);

        args.forEach((arg) => {
            str = str.replace("%@", arg);
        });

        return str;
    }
}
