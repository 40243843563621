import storage from "./storage";

const SIMULATED_FRONTEND_HOSTNAME = "simulated-frontend-hostname";
const RESET_BACKEND = "reset-backend";
const BACKEND_BADGE_ID = "badge-for-backend-override";

/**
 * When you're developing on localhost, you can append ?backend=dev1
 * to use the dev1 backend.
 *
 * This function returns the hostname that should be used to determine
 * which backend to use, the simulated hostname.
 *
 * If a different hostname is used, it also renders a non-interactive
 * badge to the screen with instructions for how to go back to
 * developing against the local environment.
 */
export default function getSimulatedHostnameAndRenderBadge(): string {
    if (window.location.hostname !== "localhost") {
        return "";
    }

    const params = new URLSearchParams(window.location.search);

    if (params.get(RESET_BACKEND) !== null) {
        storage.removeItem(SIMULATED_FRONTEND_HOSTNAME);
        return "";
    }

    switch (params.get("backend")) {
        case "dev1":
            storage.setItem(SIMULATED_FRONTEND_HOSTNAME, "dev1.spond.dev");
            break;
        case "staging1":
            storage.setItem(SIMULATED_FRONTEND_HOSTNAME, "staging1.spond.dev");
            break;
    }

    const hostname = storage.getItem(SIMULATED_FRONTEND_HOSTNAME);

    renderBadge(hostname);

    return hostname;
}

function renderBadge(hostname: string) {
    const existingBadge = document.getElementById(BACKEND_BADGE_ID);
    if (!existingBadge || !existingBadge.parentNode) return;
    if (!hostname) {
        if (existingBadge) existingBadge.parentNode.removeChild(existingBadge);
        return;
    }
    const badge = existingBadge || document.createElement("div");
    badge.id = BACKEND_BADGE_ID;
    const badgeWidth = "450px";
    Object.assign(badge.style, {
        boxSizing: "border-box",
        position: "fixed",
        zIndex: "1000000000",
        bottom: "0",
        left: `calc(50% - ${badgeWidth} / 2)`,
        width: badgeWidth,
        backgroundColor: "rgb(200, 20, 40)",
        padding: "10px",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        pointerEvents: "none",
        opacity: "0.8",
        color: "white",
        textAlign: "center",
        boxShadow: "box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        fontSize: "14px",
    });
    badge.textContent = `Emulating ${hostname}. Go to ?${RESET_BACKEND} to reset.`;
    if (!existingBadge) document.body.appendChild(badge);
}
