import { Component, Input } from "@angular/core";

@Component({
    selector: "spond-header",
    template: require("./spondHeader.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./spondHeader.component.scss").default],
})
export class SpondHeaderComponent {
    @Input()
    forCashback: boolean = false;

    assetSpondLogoUrl: any = require("./images/spond_logo.svg");

    constructor() {}
}
