import "../../../common/rxjs-operators";

import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { rootRouterConfig } from "./root.routes";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { HttpModule } from "@angular/http";

import { ModalModule } from "ng2-bootstrap/modal";

import { SharedModule } from "../shared/shared.module";
import { SpondCommonModule } from "../../../common/common.module";

import { RootComponent } from "./root.component";
import { GroupLinkModule } from "../groupLink/groupLink.module";

@NgModule({
    declarations: [RootComponent],
    imports: [
        BrowserModule,
        SharedModule,
        SpondCommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        ModalModule.forRoot(),
        GroupLinkModule,
        RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    ],
    providers: [],
    bootstrap: [RootComponent],
})
export class RootModule {}
