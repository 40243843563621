import { CurrencyService } from "../services/currency";

export class PayoutDetails {
    id: string;
    accountNo: string;
    accountOwner: string;
    currency: string;
    payoutPeriod: string;
    paidOutTimestamp: string;
    amount: number;
    history: any[];
    contributions: any[];
    vm: any = {};

    constructor(
        obj: any,
        private currencyService: CurrencyService = null,
    ) {
        this.currency = obj.currency;
        this.id = obj.id;
        this.paidOutTimestamp = obj.paidOutTimestamp;
        this.payoutPeriod = obj.payoutPeriod;
        this.accountOwner = obj.accountOwner;
        this.accountNo = obj.accountNo;
        this.history = obj.history;
        this.contributions = obj.contributions;

        this.amount = obj.amount;

        var options: any;

        options = { month: "long" };
        this.vm.month = new Date(this.payoutPeriod).toLocaleDateString("no-no", options);

        this.currencyService.formatAmountExact(obj.amount, obj.currency).subscribe((formatted) => {
            this.vm.formattedAmount = formatted;
        });

        for (let history of obj.history) {
            var historyDate = new Date(history.yearAndMonth);
            if (historyDate.getFullYear() == new Date().getFullYear()) {
                options = { month: "long" };
            } else {
                options = { month: "long", year: "numeric" };
            }

            history.month = historyDate.toLocaleDateString("no-no", options);

            this.currencyService.formatAmountExact(history.amount, this.currency).subscribe((formatted) => {
                history.formattedAmount = formatted;
            });
        }

        for (let contribution of obj.contributions) {
            this.currencyService.formatAmountExact(contribution.amount, this.currency).subscribe((formatted) => {
                contribution.formattedAmount = formatted;
            });
        }
    }
}
