import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { LocalizationService } from "../../../common/services/localization";
import { SpondCommonModule } from "../../../common/common.module";

@NgModule({
    imports: [CommonModule, FormsModule, SpondCommonModule],
    declarations: [],
    providers: [LocalizationService],
    exports: [CommonModule, FormsModule, SpondCommonModule],
})
export class SharedModule {}
