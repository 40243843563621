import { Injectable } from "@angular/core";
import { Http, Response, URLSearchParams, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { LocalizationService } from "../../services/localization";
import { Group } from "../../models/group.model";
import { Transaction } from "../../models/transaction.model";
import { PayoutDetails } from "../../models/payoutDetails.model";
import { CurrencyService } from "../../services/currency";
import { API_URL } from "../../../constants";

export enum BONUS_ERROR_CODE {
    BONUS_INVALID_ACCOUNT_NUMBER = 7001,
    BONUS_CARD_ALREADY_REGISTERED = 7002,
    BONUS_INVALID_NAME = 7003,
    BONUS_DISABLED = 7010,
}

export enum CASHBACK_PARTNER_TYPE {
    RETAIL = <any>"maincard",
    RETAIL_STOREBOX = <any>"storebox",
    SUBSCRIPTION = <any>"fjordkraft",
    ONLINE = <any>"affiliate",
}

@Injectable()
export class CashbackService {
    cachedCards: any = [];
    cachedMobileBannerAds: any = [];
    cachedWebWidgetAds: any = [];
    cachedParners: any = [];

    canLoadUserTransactions: Boolean = false;
    canLoadGroupTransactions: Boolean = false;

    lastGroupTransaction: any;
    lastUserTransaction: any;

    private BONUS_ERROR_CODE = BONUS_ERROR_CODE;

    constructor(
        private http: Http,
        private i18n: LocalizationService,
        private currencyService: CurrencyService,
    ) {}

    getTransactionColor(transaction: any) {
        if (transaction.color) {
            return transaction.color;
        } else if (transaction.isPending) {
            return "#A3A3A3";
        } else {
            var monthColors = [
                "#415798",
                "#499CDB",
                "#48B6B6",
                "#25D99D",
                "#00AF2C",
                "#30AA38",
                "#CED504",
                "#FFCF00",
                "#FFA03D",
                "#FF4B45",
                "#FF6495",
                "#7650C0",
            ];

            var dateTime = new Date(transaction.timestamp ? transaction.timestamp : transaction.payoutPeriod);
            var month = dateTime.getMonth();

            return monthColors[month];
        }
    }

    retrievePartnerById(partnerId: String) {
        var self = this;
        return Observable.create((observer) => {
            function findPartner() {
                var partner = self.cachedParners.retail.find((partner) => partner.id == partnerId);
                if (!partner) {
                    partner = self.cachedParners.subscription.find((partner) => partner.id == partnerId);
                }

                if (!partner) {
                    partner = self.cachedParners.online.find((partner) => partner.id == partnerId);
                }

                observer.next(partner);
                observer.complete();
            }

            if (self.cachedParners.retail > 0) {
                findPartner();
            } else {
                self.retrievePartners().subscribe(
                    (partners) => {
                        findPartner();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    },
                );
            }
        });
    }

    retrievePartners() {
        let params = { countryCode: "NOR", locale: "nb" };
        return this.http
            .get(`${API_URL}/bonus/partners`, { params: params })
            .map((res) => {
                this.cachedParners = res.json();

                for (let p of this.cachedParners.online) {
                    if (p.commissionGroups) {
                        for (let g of p.commissionGroups) {
                            if (g.sum) {
                                this.currencyService.formatAmountExact(g.sum, g.currency).subscribe((formatted) => {
                                    g.formattedSum = formatted;
                                });
                            }
                        }
                    }
                }

                for (let p of this.cachedParners.subscription) {
                    if (p.commissionGroups) {
                        for (let g of p.commissionGroups) {
                            if (g.sum) {
                                this.currencyService.formatAmountExact(g.sum, g.currency).subscribe((formatted) => {
                                    g.formattedSum = formatted;
                                });
                            }
                        }
                    }
                }

                return this.cachedParners;
            })
            .catch((err) => {
                return Observable.throw(err);
            });
    }

    getCards(authCode: String) {
        return Observable.create((observer) => {
            if (this.cachedCards.retail > 0) {
                observer.next(this.cachedCards);
                observer.complete();
            } else {
                this.retrieveCards(authCode).subscribe(
                    (cards) => {
                        observer.next(cards);
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    },
                );
            }
        });
    }

    retrieveCards(authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }

        return this.http
            .get(`${API_URL}/bonus/sources`, opts)
            .map((res) => {
                this.cachedCards = res.json();
                return this.cachedCards;
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    registerCard(cardNumber: String, type: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }

        let params = { cardNumber: cardNumber, type: type ? type : "MAINCARD_BANK_AXEPT" };

        return this.http
            .post(`${API_URL}/bonus/sources`, params, opts)
            .map((res) => {
                var card = res.json();
                this.cachedCards.push(card);
                return card;
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    deleteCard(id: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }

        return this.http
            .delete(`${API_URL}/bonus/sources/${id}`, opts)
            .map((res) => {
                var card = res.json();
                this.cachedCards.splice(
                    this.cachedCards.findIndex((x) => x.id == id),
                    1,
                );
                return card;
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getWebWidgetAds() {
        return this.cachedWebWidgetAds;
    }

    addAdsImpression(adsId: String) {
        return this.http
            .post(`${API_URL}/ads/${adsId}/impressions`, null)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    supportGroupCashback(groupId: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        return this.http
            .put(`${API_URL}/bonus/supportedGroups/${groupId}`, {}, opts)
            .map((res) => {
                var response = res.json();
                response.pendingDistribution = res.headers.get("cashbackpendingdistribution") === "true";
                return response;
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    stopGroupCashback(groupId: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        return this.http
            .delete(`${API_URL}/bonus/supportedGroups/${groupId}`, opts)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getSupportedCashbackGroups(authCode: String): Observable<Group[]> {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        return this.http
            .get(`${API_URL}/bonus/supportedGroups`, opts)
            .map((res) => {
                var groups = res.json();
                var constructedGroups = [];
                if (Array.isArray(groups) && groups.length > 0) {
                    for (let group of groups) {
                        constructedGroups.push(new Group(group, this.currencyService));
                    }
                }

                return constructedGroups;
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    setGroupPayoutInfo(groupId: String, payoutInfo: any) {
        return this.http
            .put(`${API_URL}/group/${groupId}/bonus/payoutInfo`, payoutInfo)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getGroupSupporters(groupId: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        return this.http
            .get(`${API_URL}/group/${groupId}/bonus/supporters`, opts)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getGroupPayoutInfo(groupId: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        return this.http
            .get(`${API_URL}/group/${groupId}/bonus/payoutInfo`, opts)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getGroupSummaryForPartner(partnerId: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        return this.http
            .get(`${API_URL}/bonus/partners/${partnerId}/groups`, opts)
            .map((res) => {
                var summaries = res.json();
                var construnctedSummmaries = [];
                if (Array.isArray(summaries) && summaries.length > 0) {
                    for (let summary of summaries) {
                        construnctedSummmaries.push(new Transaction(summary, this.currencyService));
                    }
                }

                return construnctedSummmaries;
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getTransactionTotal(authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        return this.http
            .get(`${API_URL}/bonus/total/`, opts)
            .map((res) => {
                return new Transaction(res.json(), this.currencyService);
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getTransactionDetails(transactionId: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }

        return this.http
            .get(`${API_URL}/bonus/transactions/${transactionId}`, opts)
            .map((res) => {
                return new Transaction(res.json(), this.currencyService);
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getGroupTransactionsByEndMonth(groupId: String, fromStart: Boolean, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        let params: URLSearchParams = new URLSearchParams();
        if (!!!fromStart && this.lastGroupTransaction) {
            params.set("maxTimestamp", this.lastGroupTransaction.timestamp);
        }

        opts.params = params;

        return this.http
            .get(`${API_URL}/group/${groupId}/bonus/payouts`, opts)
            .map((res) => {
                var transactions = res.json();
                var constructedTransations = [];

                this.canLoadGroupTransactions = res.headers.get("X-Spond-Continued") === "true";

                if (Array.isArray(transactions) && transactions.length > 0) {
                    for (let transaction of transactions) {
                        constructedTransations.push(new Transaction(transaction, this.currencyService));
                    }

                    this.lastGroupTransaction = constructedTransations[constructedTransations.length - 1];
                }

                return constructedTransations;
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    canLoadMoreGroupTransactions() {
        return this.canLoadGroupTransactions;
    }

    canLoadMoreUserTransactions() {
        return this.canLoadUserTransactions;
    }

    getTransactions(timestamp: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        let params: URLSearchParams = new URLSearchParams();
        params.set("maxTimestamp", !!timestamp ? timestamp : this.lastUserTransaction.timestamp);
        opts.params = params;
        return this.http
            .get(`${API_URL}/bonus/transactions`, opts)
            .map((res: Response) => {
                var transactions = res.json();
                var constructedTransations = [];

                this.canLoadUserTransactions = res.headers.get("X-Spond-Continued") == "true";

                if (Array.isArray(transactions) && transactions.length > 0) {
                    for (let transaction of transactions) {
                        constructedTransations.push(new Transaction(transaction, this.currencyService));
                    }

                    this.lastUserTransaction = constructedTransations[constructedTransations.length - 1];
                }

                return constructedTransations;
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getAffiliatePartnerRedirectUrl(partnerId, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        return this.http
            .get(`${API_URL}/bonus/affiliate/partners/${partnerId}/redirectUrl`, opts)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getSubscriptionPartnerRedirectUrl(partnerId, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        return this.http
            .get(`${API_URL}/bonus/subscription/partners/${partnerId}/redirectUrl`, opts)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getSubscriptionDeals(partnerId: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }

        return this.http
            .get(`${API_URL}/bonus/subscription/partners/${partnerId}/subscriptions`, opts)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getGroupBalance(groupId: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        return this.http
            .get(`${API_URL}/group/${groupId}/bonus`, opts)
            .map((res) => {
                return new Group(res.json(), this.currencyService);
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getPayoutDetails(groupId: String, payoutId: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }

        let params: URLSearchParams = new URLSearchParams();
        params.set("locale", "nb");

        opts.params = params;
        return this.http
            .get(
                !!payoutId
                    ? `${API_URL}/group/${groupId}/bonus/payouts/${payoutId}/details`
                    : `${API_URL}/group/${groupId}/bonus/pending/details`,
                opts,
            )
            .map((res: Response) => {
                return new PayoutDetails(res.json(), this.currencyService);
            })
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getSupportedBonusGroups(authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }
        return this.http
            .get(`${API_URL}/bonus/supportedGroups`, opts)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    showAlertForError(errorCode: any) {
        var title = this.i18n.dict().dictionary.bonus_group_account_number;
        var description = this.i18n.dict().dictionary.general_unknown_error_description;

        if (errorCode === BONUS_ERROR_CODE.BONUS_CARD_ALREADY_REGISTERED) {
            title = this.i18n.dict().dictionary.bonus_add_bankcard_already_registered_title;
            description = this.i18n.dict().dictionary.bonus_add_bankcard_already_registered_description;
        } else if (errorCode === BONUS_ERROR_CODE.BONUS_INVALID_ACCOUNT_NUMBER) {
            title = this.i18n.dict().dictionary.bonus_add_bankcard_invalid_title;
            description = this.i18n.dict().dictionary.bonus_add_bankcard_invalid_description;
        } else if (errorCode === BONUS_ERROR_CODE.BONUS_INVALID_NAME) {
            title = this.i18n.dict().dictionary.bonus_group_add_account_number_invalid_name_title;
            description = this.i18n.dict().dictionary.bonus_group_add_account_number_invalid_name_description;
        }

        // commonModals.showGeneralNotification(
        //     title,
        //     description,
        //     undefined,
        //     this.i18n.dict().dictionary.general_ok);
    }
}
