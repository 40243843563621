// angular
//     .module('components.bonusPartnerCell')
//     .component('bonusPartnerCell', {
//         template: require('/static/partials/components/bonusPartnerCell/bonusPartnerCell.component.tpl.html').default,
//         controller: 'BonusPartnerCellController',
//         bindings: {
//             partner:"<"
//         }
//     })
//     .controller('BonusPartnerCellController', [
//         '$scope', '$state',
//         BonusPartnerCellController
//     ]);

// function BonusPartnerCellController($scope, $state) {
//     var self = this;

//     self.onClickedPartner = function () {
//         $state.go('bonus.partnerDetails', {id: self.partner.id, partner:self.partner});
//     }
// }

import { Component, Input, OnInit } from "@angular/core";
import { Router, Resolve, ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { CommonModule } from "@angular/common";
import { LocalizationService } from "../../services/localization";

@Component({
    selector: "cashback-partner-cell",
    template: require("./cashbackPartnerCell.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./cashbackPartnerCell.component.scss").default],
})
export class CashbackPartnerCellComponent {
    @Input()
    partner: any;
    authCode: String;
    profileId: String;

    constructor(
        public i18n: LocalizationService,
        private router: Router,
        private activeRouter: ActivatedRoute,
    ) {
        activeRouter.queryParams.subscribe((params) => {
            this.authCode = params["authCode"];
            this.profileId = params["profileId"];
        });
    }
    ngOnInit() {
        console.log("cashbackPartnerCell");
    }

    onClickedPartner() {
        this.router.navigate(["partner/" + this.partner.id], {
            queryParams: { authCode: this.authCode, profileId: this.profileId },
        });
    }
}
