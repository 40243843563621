import { Component, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "root",
    template: require("./root.component.html").default,
    styles: [
        require("../../../common/styles/index.scss").default,
        require("../../../common/fonts/style.scss").default,
        require("./root.component.scss").default,
    ],
    encapsulation: ViewEncapsulation.None, // Allow root styles to apply to parent
})
export class RootComponent {}
