import * as Sentry from "@sentry/browser";
import { hostnames } from "./constants";
import { RELEASE } from "./buildinfo";

const SENTRY_DSN = "https://00475774ace34ffda7cc5baa49227fbf@o74102.ingest.sentry.io/1208888";

for (const [envName, hostname] of Object.entries(hostnames)) {
    if (hostname === window.location.hostname) {
        Sentry.init({
            dsn: SENTRY_DSN,
            release: RELEASE,
            environment: envName,
        });
        break;
    }
}
