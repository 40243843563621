import { Component, Input } from "@angular/core";
import { LocalizationService } from "../../services/localization";

@Component({
    selector: "spond-footer",
    template: require("./spondFooter.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./spondFooter.component.scss").default],
})
export class SpondFooterComponent {
    @Input()
    note: String;

    appStoreIconUrl: String;
    playStoreIconUrl: String;

    constructor(public i18n: LocalizationService) {
        if (!this.note) {
            this.note = i18n.dict().landing_apps_page_footer_label;
        }

        this.appStoreIconUrl = require(`../../../assets/app-${this.i18n.getLocale()}@2x.png`);
        this.playStoreIconUrl = require(`../../../assets/play-${this.i18n.getLocale()}@2x.png`);
    }
}
