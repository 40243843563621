import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "spinner-button",
    template: require("./spinnerButton.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./spinnerButton.component.scss").default],
})
export class SpinnerButtonComponent implements OnInit {
    @Input() buttonTheme: string;
    @Input() spinnerTheme: string;
    @Input() isSpinning: boolean;
    @Input() isDisabled: boolean;

    @Output() callback: EventEmitter<any> = new EventEmitter();

    resSpinnerWhite: any = require("./assets/spinner-white.gif");
    resSpinnerBlack: any = require("./assets/spinner-black.gif");
    spinnerResource: string;

    ngOnInit() {
        if (this.spinnerTheme == "spinner-white") {
            this.spinnerResource = this.resSpinnerWhite;
        } else {
            this.spinnerResource = this.resSpinnerBlack;
        }
    }

    onClicked() {
        if (this.callback) {
            this.callback.emit();
        }
    }
}
