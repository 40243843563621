// angular
//     .module('components.bonusRightColumn')
//     .component('bonusRightColumn', {
//         template: require('/static/partials/components/bonusRightColumn/bonus-right-column.component.tpl.html').default,
//         controller: 'BonusRightColumnController',
//         bindings: {
//         }
//     })
//     .controller('BonusRightColumnController', [
//         '$scope', '$state', 'BONUS_LEFT_MENU_ITEM',
//         BonusRightColumnController
//     ]);

// function BonusRightColumnController($scope, $state, BONUS_LEFT_MENU_ITEM) {
//     var self = this;
// }

import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocalizationService } from "../../services/localization";

@Component({
    selector: "cashback-right-column",
    template: require("./cashbackRightColumn.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./cashbackRightColumn.component.scss").default],
})
export class CashbackRightColumnComponent implements OnInit {
    constructor(public i18n: LocalizationService) {}

    ngOnInit() {
        console.log("CashbackRightColumnComponent");
    }
}
