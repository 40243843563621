import { Component } from "@angular/core";

@Component({
    selector: "spinner",
    template: require("./spinner.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./spinner.component.scss").default],
})
export class SpinnerComponent {
    assetSpondLogoUrl: any = require("./images/spinner.gif");
}
