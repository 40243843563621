import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "removeHighlight" })
export class RemoveHighlightPipe implements PipeTransform {
    transform(input: String, url: String, url1: String) {
        if (typeof input !== "string") {
            return undefined;
        }

        input = input.replace(/<highlight>/g, "");
        return input.replace(/<\/highlight>/g, "");
    }
}
