import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocalizationService } from "../../services/localization";

@Component({
    selector: "partner-cell",
    template: require("./partnerCell.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./partnerCell.component.scss").default],
})
export class PartnerCellComponent {
    @Input()
    partner: any;
    @Input()
    onlyLogo: Boolean = false;

    constructor(public i18n: LocalizationService) {}

    ngOnInit() {}
}
