import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";

import { SpondHeaderComponent } from "./components/spondHeader";
import { SpondFooterComponent } from "./components/spondFooter";
import { SpinnerComponent } from "./components/spinner";

import { PartnerCellComponent } from "./components/partnerCell/partnerCell.component";

import { ReplaceHighlightPipe } from "./pipes/replaceHighlight.pipe";
import { RemoveHighlightPipe } from "./pipes/removeHighlight.pipe";

import { CashbackLeftMenuComponent } from "./components/cashbackLeftMenu/cashbackLeftMenu.component";
import { CashbackGetStartedComponent } from "./components/cashbackGetStarted/cashbackGetStarted.component";
import { CashbackGroupCellComponent } from "./components/cashbackGroupCell/cashbackGroupCell.component";
import { CashbackPartnerCellComponent } from "./components/cashbackPartnerCell/cashbackPartnerCell.component";
import { CashbackRightColumnComponent } from "./components/cashbackRightColumn/cashbackRightColumn.component";
import { CashbackSupportedGroupComponent } from "./components/cashbackSupportedGroup/cashbackSupportedGroup.component";
import { GroupCoverImageComponent } from "./components/groupCoverImage/groupCoverImage.component";

@NgModule({
    imports: [CommonModule, BrowserModule],
    declarations: [
        SpondHeaderComponent,
        SpondFooterComponent,
        SpinnerComponent,
        PartnerCellComponent,
        GroupCoverImageComponent,
        CashbackLeftMenuComponent,
        CashbackGetStartedComponent,
        CashbackGroupCellComponent,
        CashbackPartnerCellComponent,
        CashbackRightColumnComponent,
        CashbackSupportedGroupComponent,
        ReplaceHighlightPipe,
        RemoveHighlightPipe,
    ],
    providers: [],
    exports: [
        SpondHeaderComponent,
        SpondFooterComponent,
        SpinnerComponent,
        PartnerCellComponent,
        GroupCoverImageComponent,
        CashbackLeftMenuComponent,
        CashbackGetStartedComponent,
        CashbackGroupCellComponent,
        CashbackPartnerCellComponent,
        CashbackRightColumnComponent,
        CashbackSupportedGroupComponent,
        ReplaceHighlightPipe,
        RemoveHighlightPipe,
    ],
})
export class SpondCommonModule {}
