// angular
//     .module('components.bonusSupportedGroup')
//     .component('bonusSupportedGroup', {
//         template: require('/static/partials/components/bonusSupportedGroup/bonusSupportedGroup.component.tpl.html').default,
//         controller: 'BonusSupportedGroupController',
//         bindings: {
//             bonusGroups:'<'
//         }
//     })
//     .controller('BonusSupportedGroupController', [
//         '$scope', '$state', 'groupModelFactory',
//         BonusSupportedGroupController
//     ]);

// function BonusSupportedGroupController($scope, $state, groupModelFactory) {
//     var self = this;

//     self.onGroupClicked = function (group) {
//         $state.go('bonus.groupInfo', {groupId: group.id});
//     };
// }

import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocalizationService } from "../../services/localization";

import { Router, Resolve, ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";

@Component({
    selector: "cashback-supported-group",
    template: require("./cashbackSupportedGroup.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./cashbackSupportedGroup.component.scss").default],
})
export class CashbackSupportedGroupComponent implements OnInit {
    @Input()
    supportedGroups: any;
    authCode: String;
    profileId: String;

    constructor(
        public i18n: LocalizationService,
        private router: Router,
        private activeRouter: ActivatedRoute,
    ) {
        activeRouter.queryParams.subscribe((params) => {
            this.authCode = params["authCode"];
        });
    }

    onGroupClicked(group) {
        this.router.navigate([`group/${group.supportCode}`], {
            queryParams: { authCode: this.authCode, profileId: this.profileId },
        });
    }

    onDistribute() {
        this.router.navigate(["distribute"], { queryParams: { authCode: this.authCode, profileId: this.profileId } });
    }

    ngOnInit() {}
}
