import { Injectable } from "@angular/core";
import { Http, Response, URLSearchParams, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { API_URL } from "../../../constants";
import "rxjs/add/operator/share";

export class Config {
    divisor: string;
    decimals: boolean;

    constructor(obj: any) {
        this.divisor = obj.divisor;
        this.decimals = obj.decimals;
    }
}

@Injectable()
export class CurrencyService {
    cache: any = [];

    currencyOberver: any = null;

    constructor(private http: Http) {
        this.getCurrencyConfig("NOK");
    }

    getCurrencyConfig(currency: string) {
        var self = this;

        if (!currency || self.cache[currency]) {
            return new Observable((observer) => {
                if (!currency) {
                    observer.error();
                    observer.complete();
                }

                if (self.cache[currency]) {
                    observer.next(self.cache[currency]);
                    observer.complete();
                }

                return { unsubscribe() {} };
            });
        } else {
            if (!self.currencyOberver) {
                self.currencyOberver = this.http
                    .get(`${API_URL}/currencies/${currency}`)
                    .map((res) => {
                        var data = res.json();
                        self.cache[currency] = data;

                        self.currencyOberver = null;
                        return data;
                    })
                    .share();
            }

            return self.currencyOberver;
        }

        // return this.http.get(`${API_URL}/currencies/${currency}`)
        //     .map(res => {
        //         var data = res.json();
        //         this.cache[currency] = data;
        //         return data;
        //     })
    }

    formatAmountSimple(amount: number, currency: string, locale: string = "no") {
        if (this.cache[currency]) {
            var config: any = this.cache[currency];
            var sum: any = (amount / config.divisor).toLocaleString(locale, {
                minimumFractionDigits: config.decimals,
                maximumFractionDigits: config.decimals,
                useGrouping: true,
            });
            return new Observable((observer) => {
                observer.next(String.prototype.replace.call(config.simpleFormat, "{}", sum));
                observer.complete();

                return { unsubscribe() {} };
            });
        } else {
            return this.getCurrencyConfig(currency).map((config: any) => {
                var sum = (amount / config.divisor).toLocaleString(locale, {
                    minimumFractionDigits: config.decimals,
                    maximumFractionDigits: config.decimals,
                    useGrouping: true,
                });
                return String.prototype.replace.call(config.simpleFormat, "{}", sum);
            });
        }
    }

    formatAmountFull(amount: number, currency: string, locale: string = "no") {
        if (this.cache[currency]) {
            var config: any = this.cache[currency];
            var sum = (amount / config.divisor).toLocaleString(locale, {
                minimumFractionDigits: config.decimals,
                maximumFractionDigits: config.decimals,
                useGrouping: true,
            });
            return new Observable((observer) => {
                observer.next(String.prototype.replace.call(config.fullFormat, "{}", sum));
                observer.complete();

                return { unsubscribe() {} };
            });
        } else {
            return this.getCurrencyConfig(currency).map((config: any) => {
                var sum = (amount / config.divisor).toLocaleString(locale, {
                    minimumFractionDigits: config.decimals,
                    maximumFractionDigits: config.decimals,
                    useGrouping: true,
                });
                return String.prototype.replace.call(config.fullFormat, "{}", sum);
            });
        }
    }

    formatAmountExact(amount: number, currency: string, locale: string = "no") {
        if (this.cache[currency]) {
            var config: any = this.cache[currency];
            var sum = (amount / config.divisor).toLocaleString(locale, {
                minimumFractionDigits: config.exactDecimals,
                maximumFractionDigits: config.exactDecimals,
                useGrouping: true,
            });
            return new Observable((observer) => {
                observer.next(String.prototype.replace.call(config.exactFormat, "{}", sum));
                observer.complete();

                return { unsubscribe() {} };
            });
        } else {
            return this.getCurrencyConfig(currency).map((config: any) => {
                var sum = (amount / config.divisor).toLocaleString(locale, {
                    minimumFractionDigits: config.exactDecimals,
                    maximumFractionDigits: config.exactDecimals,
                    useGrouping: true,
                });
                return String.prototype.replace.call(config.exactFormat, "{}", sum);
            });
        }
    }
}
