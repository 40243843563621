import { Injectable } from "@angular/core";
import { Http, Response, URLSearchParams } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { API_URL } from "../../../../constants";

@Injectable()
export class GroupLinkService {
    constructor(private http: Http) {}

    getGroupInfoByCode(code: String) {
        return this.http
            .get(`${API_URL}/groupSignupCode/${code}`)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    requestMembershipByEmail(code: String, email: String, name: String) {
        let params = {
            email: email,
            fullName: name,
        };

        let url = `${API_URL}/groupSignupCode/${code}/requestMembershipByEmail`;
        return this.http
            .post(url, params)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }
}
