import { Component, OnInit, ViewChild, style, state, animate, transition, trigger } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { Router, Resolve, ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { ModalDirective } from "ng2-bootstrap/modal";
import { Observable } from "rxjs/Observable";
import { Title } from "@angular/platform-browser";

import { ConfirmModalComponent, DeferredClickEvent } from "../../../common/components/confirmModal";
import { SpinnerButtonComponent } from "../../../common/components/spinnerButton";
import { LocalizationService } from "../../../common/services/localization";
import { GroupLinkService } from "./shared/groupLink.service";

enum GroupLinkState {
    Home = 1,
    RegisterOnWeb = 10,
    Registering = 11,
    Confirmation = 20,
    GeneralError = 99,
}

enum SIGNUP_CODE_ERROR_CODES {
    GROUP_SIGNUP_ALREADY_MEMBER = 2201,
    GROUP_SIGNUP_PENDING = 2202,
}

@Component({
    selector: "group-link",
    template: require("./groupLink.component.html").default,
    styles: [require("../../../common/styles/index.scss").default, require("./groupLink.component.scss").default],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger("fadeInAnimation", [
            transition(":enter", [style({ opacity: 0 }), animate(".5s 400ms", style({ opacity: 1 }))]),
        ]),
        trigger("fadeOutAnimation", [
            transition(":leave", [style({ opacity: 1 }), animate(".5s", style({ opacity: 0 }))]),
        ]),
    ],
})
export class GroupLinkComponent implements OnInit {
    @ViewChild("btnRegister")
    btnRegister: SpinnerButtonComponent;
    @ViewChild("confirmErrorModal")
    confirmErrorModal: ConfirmModalComponent;
    @ViewChild("userCheckEmail")
    userCheckEmail: any;

    public GroupLinkState = GroupLinkState;

    public SIGNUP_CODE_ERROR_CODES = SIGNUP_CODE_ERROR_CODES;

    state: GroupLinkState;
    group: any;
    signupCode: String;
    userEmail: String;
    name: String;
    isIOS: Boolean;

    constructor(
        private i18n: LocalizationService,
        private titleService: Title,
        private service: GroupLinkService,
        private router: ActivatedRoute,
    ) {}

    errorHandler(error) {
        if (this.btnRegister) {
            this.btnRegister.isSpinning = false;
        }

        if (error.errorCode == SIGNUP_CODE_ERROR_CODES.GROUP_SIGNUP_PENDING) {
            this.confirmErrorModal.show(
                this.group.name,
                this.i18n.get("group_code_already_requested_membership_title", this.group.name),
                null,
                this.i18n.dict().general_ok,
                false,
            );
        } else if (error.errorCode == SIGNUP_CODE_ERROR_CODES.GROUP_SIGNUP_ALREADY_MEMBER) {
            this.confirmErrorModal.show(
                this.group.name,
                this.i18n.get("group_code_already_member_title", this.group.name),
                null,
                this.i18n.dict().general_ok,
                false,
            );
        } else {
            this.confirmErrorModal.show(
                this.i18n.dict().group_code_invalid_title,
                this.i18n.dict().group_code_invalid_description,
                null,
                this.i18n.dict().general_ok,
                false,
            );
        }
    }

    onConfirmErrorClicked(event: DeferredClickEvent) {
        event.success();
    }

    onDownloadAppClicked() {
        if (this.isIOS) {
            window.location.href = "https://ios.spond.com";
        } else {
            window.location.href = "https://android.spond.com";
        }
    }

    onRegisterOnWebClicked() {
        this.state = GroupLinkState.RegisterOnWeb;
    }

    onRegisterClicked() {
        if (this.userCheckEmail.invalid) {
            this.confirmErrorModal.show(
                this.i18n.dict().general_title_error,
                this.i18n.dict().error_invalid_email,
                null,
                this.i18n.dict().general_ok,
                false,
            );
            return;
        }

        this.btnRegister.isSpinning = true;
        this.service.requestMembershipByEmail(this.signupCode, this.userEmail, this.name).subscribe(
            () => {
                this.state = GroupLinkState.Confirmation;
            },
            (error) => this.errorHandler(error),
        );
    }

    ngOnInit() {
        this.state = GroupLinkState.Home;
        this.signupCode = window.location.pathname.split("/").pop();
        this.isIOS =
            window.navigator.userAgent.indexOf("iPhone") !== -1 ||
            window.navigator.userAgent.indexOf("iPad") !== -1 ||
            window.navigator.userAgent.indexOf("iPod") !== -1;
        this.service.getGroupInfoByCode(this.signupCode).subscribe(
            (group) => {
                this.group = group;
            },
            (error) => this.errorHandler(error),
        );
        this.userEmail = "";
    }
}
