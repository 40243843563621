// angular
//     .module('components.bonusGroupCell')
//     .component('bonusGroupCell', {
//         template: require('/static/partials/components/bonusGroupCell/bonusGroupCell.component.tpl.html').default,
//         controller: 'BonusGroupCellController',
//         bindings: {
//             group:'<'
//         }
//     })
//     .controller('BonusGroupCellController', [
//         '$scope',
//         BonusGroupCellController
//     ]);

// function BonusGroupCellController($scope) {
//     var self = this;

// }

import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocalizationService } from "../../services/localization";

@Component({
    selector: "cashback-group-cell",
    template: require("./cashbackGroupCell.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./cashbackGroupCell.component.scss").default],
})
export class CashbackGroupCellComponent implements OnInit {
    @Input()
    group: any;

    constructor(public i18n: LocalizationService) {}

    onGroupChosen() {}

    ngOnInit() {}
}
